import { useEffect, useState } from "react";

const Time = () => {


    const dayNames = ['Dim','Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', ];
    const monthNames = ['Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai', 'Juin', 'Jui.', 'Aou.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

    const [today, setDate] = useState(new Date());
    
    useEffect(() => {
        setDate(new Date());
    }, []);

    useEffect(() => {
        try {
            //const currentTime = window.webapis.getCurrentTime();

        } catch (err) {
            console.error('ERROR: ', err);
        }
    }, []);

    const time = today.getHours() + ':' + ('0'+today.getMinutes()).slice(-2);
    const date = dayNames[today.getDay() || 0] + ' ' + today.getDate().toLocaleString() + ' ' + monthNames[today.getMonth() || 0] + ' ' + today.getFullYear();

    let count = 0;

    useEffect(() => {
        setInterval(() => {
            setDate(new Date());
            count < 10 ? count++ : count = 0;
        }, 60 * 1000);
        return () => clearInterval();
    }, []);
    
    return (
        <>
            <div className="fullDate">
                {date},   {time}
                {/*<div className="trait"></div>*/}
                {/*<div className="time">*/}

                {/*</div>*/}
            </div>
        </>
    );
}

export default Time;