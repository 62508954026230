import Position from "./Position";
import Time from "./Time";

const Widgets = () => {
    return(
        <>
            <div className="date">
                <Time />
            </div>
            {/* <div className="meteo">
                <Position />
            </div> */}
        </>
    );
}
export default Widgets;