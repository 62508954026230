// Dossier de stockage des médias sur les écrans
export const TV_localStoragePath = "file:///opt/usr/home/owner/content/Images/";

// Taille des chunks utilisés pour écrire sur l'écran les données bloques par bloques (en bytes)
export const TV_writeChunkSize = 1000000;
export const TV_downloadVideoChunkSize = 5000000;

// Tizen won't write correctly for file with name longer than 50 characters
export const TV_storageMaxFileLen = 50;

export const parseUrl = (url, identifier, type) => {
    const regexPattern = new RegExp(`file:///`); // REGEX to avoid concatenating the string if the category id exists.
    let filenameWithExtension = url.substring(url.lastIndexOf('/') + 1).split('?')[0];
    if (!regexPattern.test(url))
        filenameWithExtension = type + identifier + "_" + decodeURI(filenameWithExtension);
    let filename = filenameWithExtension.substring(0, filenameWithExtension.lastIndexOf('.'));
    const extension = filenameWithExtension.split('.').pop().split('?')[0];
    const queryString = url.substring(url.indexOf('?') + 1);
    const queryParams = {};
    queryString.split('&').forEach((query) => {
        const [key, value] = query.split('=');
        queryParams[key] = value;
    });

    // Cut filename if it exceeds TV_storageMaxFileLen characters
    const maxLength = TV_storageMaxFileLen;
    if (filename.length > maxLength) {
        filename = filename.substring(0, maxLength);
    }

    // Adjust filenameWithExtension if filename was truncated
    if (filenameWithExtension.length > maxLength) {
        filenameWithExtension = filename + filenameWithExtension.substring(filenameWithExtension.lastIndexOf('.'));
    }

    return {
        filename: filename,
        extension: extension,
        file: filenameWithExtension,
        queryParams: queryParams,
        url: url
    };
};

export const fetchVideos = async (url, progressCallback) => {
    try {
        let start = 0;
        let end = TV_downloadVideoChunkSize - 1;
        let byteArray = new Uint8Array(0);

        // Fetching chunks until the end or error
        while (true) {
            const response = await fetch(url, {
                headers: {
                    Range: `bytes=${start}-${end}`
                }
            });

            if (!response.ok) {
                if (response.status === 416) {
                    // 416 indicates we've reached the end of the file
                    break;
                } else {
                    throw new Error(`Failed to fetch chunk: ${response.status}`);
                }
            }

            const chunk = await response.arrayBuffer();
            const chunkArray = new Uint8Array(chunk);
            const newByteArray = new Uint8Array(byteArray.length + chunkArray.length);
            newByteArray.set(byteArray);
            newByteArray.set(chunkArray, byteArray.length);
            byteArray = newByteArray;

            start = end + 1;
            end = start + TV_downloadVideoChunkSize - 1;
            if (progressCallback) {
                progressCallback(start);
            }
            if (chunk.byteLength > TV_downloadVideoChunkSize) // Download range isn't available
                break;
        }

        return byteArray;
    } catch (error) {
        console.error('Error fetching thumbnail:', error);
        return null;
    }
};

export const fetchThumbnail = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET"
        });
        if (response.ok) {
            const data = await response.arrayBuffer();

            // Convert the ArrayBuffer to a Uint8Array (byte array)
            const byteArray = new Uint8Array(data);

            return byteArray;
        } else {
            console.error("Error with response: " + response);
        }
    } catch (error) {
        console.error('Error fetching settings:', error);
    }
    return null;
}

export const checkFileExists = async (file) => {
    const filePath = `${TV_localStoragePath}${file}`;
    return new Promise((resolve, reject) => {
        window.tizen.filesystem.resolve(
            filePath,
            function onSuccess(fileHandle) {
                resolve(filePath);
            },
            function onError(error) {
                resolve(null);
            },
            'r'
        );
    });
}

export const deleteDuplicateFiles = (exceptFile, pattern) => {
    let directoryPath = TV_localStoragePath;
    window.tizen.filesystem.resolve(directoryPath, function(dir) {
        // List the directory contents
        dir.listFiles(function(files) {
            // Iterate through the files
            files.forEach(function(file) {
                // Check if the file starts with the pattern and is not the one to exclude
                if ((file.name.startsWith(pattern) && file.name !== exceptFile) || (file.fileSize !== undefined && file.fileSize === 0)) {
                    // Delete the file
                    dir.deleteFile(file.fullPath, function() {
                    }, function(error) {
                        console.error('Error deleting file: ' + file.name + ', ' + error.message);
                    });
                }
            });
        }, function(error) {
            console.error('Error listing files: ' + error.message);
        }, {mode: 'rw'});
    }, function(error) {
        console.error('Error resolving directory: ' + error.message);
    }, 'rw');
}

// Function to write data to a file in the "images" directory.
export const writeToStorage = (file, data, onComplete = null) => {
    var dfile = decodeURI(file);
    var pattern = dfile.split("_")[0] + "_";
    deleteDuplicateFiles(dfile, pattern);
    console.log("Writting file", dfile);
    return new Promise((resolve, reject) => {
        window.tizen.filesystem.resolve("images", (dir) => {
            try {
                const fh = dir.createFile(dfile);
                if (fh.isFile) {
                    fh.openStream('w', (fs) => {
                        try {
                            if (data.length === 0) {
                                console.error("Received data is empty");
                                reject("empty");
                            }
                            for (let i = 0; i < data.length; i += TV_writeChunkSize) {
                                const slicedData = data.slice(i, i + TV_writeChunkSize);
                                if (slicedData.length === 0) {
                                    break; // No more data to process
                                }
                                const byteArr = Array.from(new Uint8Array(slicedData));
                                console.log("writing chunk from " + i + " to " + (i + slicedData.length));
                                fs.writeBytes(byteArr);
                            }
                            console.log(dfile, "written to device storage.");
                            if (onComplete)
                                onComplete();
                            resolve(fh.toURI());
                        } catch (e) {
                            if (onComplete)
                                onComplete();
                            reject(e);
                        }
                        fs.close();
                    }, (error) => {
                        if (onComplete)
                            onComplete();
                        reject("Error opening stream: " + error.message);
                    }, 'UTF-8');
                }
            } catch (e) {
                console.error("Tried to overwrite a file but Tizen Filesystem API forbids it.");
                console.error("   Caused by: " + dfile);
                console.error("   Error: " + e);
                if (onComplete)
                    onComplete();
                // reject(e); Do not reject as it will trigger an infinite image loading error (Need to have video thumbnail and pdf preview on blocks)
                resolve("/img/empty_thumbnail.png"); // Returns the empty_thumbnail file from public folder
            }
        }, (error) => {
            if (onComplete)
                onComplete();
            reject("Error resolving directory: " + error.message);
        }, 'rw');
    });
};

// Returns the Tizen Version as string. Ex: '5.0'
export const getTizenVersion = () => {
    return window.tizen.systeminfo.getCapability("http://tizen.org/feature/platform.version", function(version) {}, function(error) {});
}
