import { useEffect, useState } from 'react';
import Weather from "./Weather";
import Screensaver from "./ScreenSaver"; // Import the Screensaver component
import APIURL from "../api/dns";

const Position = () => {
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [locationName, setLocationName] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [screensaverLocked, setScreensaverLocked] = useState(false); // New state for screensaver_locked

    useEffect(() => {
        const deviceCode = localStorage.getItem('Device-Code');
        const deviceUUID = localStorage.getItem('Device-UUID');

        if (!deviceCode || !deviceUUID) {
            console.error('Device code or UUID is missing');
            return;
        }

        const initApiURL = `${APIURL}/api/device/code/${deviceCode}`;
        const initReqOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Device-Code": deviceCode,
                "Device-UUID": deviceUUID
            }
        };

        fetch(initApiURL, initReqOptions)
            .then(resp => resp.json())
            .then(data => {
                setLatitude(data.latitude);
                setLongitude(data.longitude);
                setLocationName(data.location_name);
                setScreensaverLocked(data.screensaver_locked); // Set screensaver_locked
                setIsLoaded(true);
            })
            .catch(error => console.error('Failed to load data', error));
    }, []);

    if (!isLoaded) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Weather latitude={latitude} longitude={longitude} locationName={locationName} />
            {/*<Screensaver screensaverLocked={screensaverLocked} /> /!* Pass screensaverLocked to Screensaver *!/*/}
        </>
    );
};

export default Position;
