import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OVHAUTH from "../token-ovh/ovh-auth";

const AuthError = () => {

    const navigate = useNavigate();

    const clearAndGo = () => {
        localStorage.clear();
        navigate(OVHAUTH);
    }

    return(
        <div className="background-view">
            <div className="connexion-error-content">
                <h1>ERREUR DE CONNEXION</h1>
                <h3>Vérifiez vos identifiants de connexion</h3>
                <Button variant="dark" onClick={() => clearAndGo()}>Connectez-vous</Button>
            </div>
        </div>
    );

}

export default AuthError;