import { useState, useEffect } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import APIURL from "../api/dns";
import OVHAUTH from "../token-ovh/ovh-auth";

const Login = (props) => {

    const [password, setPassword] = useState("");

    const apiURL = APIURL +'/api/device/';

    useEffect(() => {
        checkDeviceToken();
    }, []);


    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const urlToken = searchParams.getAll("token")[0];
    const deviceTokenApiUrl = `${apiURL}token/` + urlToken;
    console.log(urlToken);

    // Check if the token in the url exists in the backend
    const checkDeviceToken = () => {
        const reqOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        if (urlToken) {
            fetch(deviceTokenApiUrl, reqOptions)
                .then(async (resp) => {
                    let data = await resp.json();
                    if (resp.status === 200) {
                        localStorage.setItem('Device-Code', data.code);
                        localStorage.setItem('Device-UUID', data.uuid);
                        localStorage.setItem('Device-Token', data.token);
                        localStorage.setItem('Url-Token', urlToken);
                        props.setQRCodeURL(`https://iframe.multiapp.fr?token=${urlToken}`);
                        navigate(OVHAUTH+'/home');
                    } else {
                        alert('Nom de compte ou mot de passe erroné.\n Vérifiez les et essayez à nouveau.');
                    }
                })
        }
    }


    // "uuid": localStorage.getItem('UUID')

    const navigate = useNavigate();

    // const checkDeviceCode = (e) => {
    //     const deviceCodeApiUrl = `${apiURL}code/` + password;
    //
    //     e?.preventDefault();
    //     const reqOptions = {
    //         method: 'PATCH',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             // Activate below for PROD
    //             "uuid": localStorage.getItem("Device-UUID")
    //
    //             // Activate below for DEV
    //            //"uuid": "brrr"
    //         })
    //     }
    //
    //     fetch(deviceCodeApiUrl, reqOptions)
    //         .then(async (resp) => {
    //             let data = await resp.json();
    //             if (resp.status === 200) {
    //                 //console.log("test", data);
    //                 localStorage.setItem('Device-Code', data.code);
    //                 localStorage.setItem('Device-UUID', data.uuid);
    //                 localStorage.setItem('Device-Token', data.token);
    //
    //                 // Activate below for DEV if there is no UUID in the tablet/screen used
    //                 //localStorage.setItem('Device-UUID', 'brrr');
    //                 navigate(OVHAUTH +'/home');
    //             } else {
    //                 alert('Nom de compte ou mot de passe erroné.\n Vérifiez les et essayez à nouveau.');
    //             }
    //         })
    // }
    return null;
}

export default Login;