import { useEffect, useRef, useState, useCallback } from "react";
import ReactPlayer from 'react-player';
import { useNavigate } from "react-router-dom";
import APIURL from "../api/dns";
import OVHAUTH from "../token-ovh/ovh-auth";
import { fetchThumbnail, checkFileExists, writeToStorage, parseUrl, fetchVideos, TV_localStoragePath } from '../api/Tizen';
import LoadingScreen from "./LoadingScreen";

const ScreenSaver = ({ isActive, setIsActive, triggerCooldown }) => {
    const idleTime = triggerCooldown ? triggerCooldown : 300;
    const [saverItems, setSaverItems] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const videoRef = useRef(null);
    const idleTimerRef = useRef(null);
    const navigate = useNavigate();
    const imageDisplayDuration = 5000; // Durée d'affichage d'une image en millisecondes
    const imageTimeoutRef = useRef(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [jobCount, setJobCount] = useState(0);

    // Récupération des éléments pour l'écran de veille
    const fetchSaverItems = useCallback(async () => {
        try {
            const response = await fetch(`${APIURL}/api/display/screensaver/`, {
                method: "GET",
                headers: {
                    "Device-Code": localStorage.getItem("Device-Code"),
                    "Device-UUID": localStorage.getItem("Device-UUID")
                }
            });
            const data = await response.json();
            if (data.length > 0) {
                if (window.tizen) {
                    for (var i = 0; i < data.length; i++) {
                        const urlData = parseUrl(data[i].file, data[i].id, 's');
                        let filePath = await checkFileExists(urlData.file);
                        if (!filePath) {
                            setJobCount(prevCount => prevCount + 1);
                            let eventData;
                            if (urlData.extension === "mp4") {
                                eventData = await fetchVideos(urlData.url, (progress) => {
                                    console.log(`Download progress: ${progress}bytes`);
                                }).then(async chunks => {
                                    console.log('Download complete!', chunks.length);
                                    try {
                                        await writeToStorage(urlData.file, chunks, () => {
                                            setJobCount(prevCount => prevCount - 1); // Decrement jobCount once storage write is completed to disable loading screen
                                        });
                                    } catch (e) {
                                        console.error("Failed to write to device storage.");
                                        setJobCount(prevCount => prevCount - 1);
                                    }
                                }).catch(error => {
                                    console.error('Error fetching MP4:', error);
                                });
                            } else {
                                eventData = await fetchThumbnail(urlData.url);
                                try {
                                    await writeToStorage(urlData.file, eventData, () => {
                                        setJobCount(prevCount => prevCount - 1); // Decrement jobCount once storage write is completed to disable loading screen
                                    });
                                } catch (e) {
                                    console.error("Failed to write to device storage.");
                                    setJobCount(prevCount => prevCount - 1);
                                }
                            }
                        }
                        const newUrl = `${TV_localStoragePath}${urlData.filename}.${urlData.extension}`;
                        data[i].file = newUrl;
                    }
                }
                setSaverItems(data);
                setCurrentIndex(0); // Réinitialiser l'index au début de la nouvelle liste
            } else {
                // Si aucun élément n'est renvoyé par l'API, naviguer vers la page d'accueil
                navigate(OVHAUTH + '/home');
                setIsActive(true); // Arrête l'écran de veille
            }
        } catch (error) {
            console.error('Erreur de chargement des éléments de l’écran de veille', error);
        }
    }, [navigate, setIsActive]);

    // Réinitialisation du timer d'inactivité lors de l'interaction
    const resetIdleTimer = useCallback(() => {
        clearTimeout(idleTimerRef.current);
        idleTimerRef.current = setTimeout(() => {
            setIsActive(false);
        }, idleTime * 1000);
        setIsActive(true); // Arrête l'écran de veille
    }, [setIsActive]);

    // Mise à jour de la liste à chaque activation
    useEffect(() => {
        if (!isActive) {
            navigate(OVHAUTH + '/home');
            fetchSaverItems();
        }
    }, [isActive, fetchSaverItems, navigate]);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            resetIdleTimer();
            // navigate(OVHAUTH + '/home');
            console.log("Handle Online")
        };
        
        const handleOffline = () => {
            setIsOnline(false);
            setIsActive(true); // Arrête l'écran de veille
            console.log("Handle Offline")
            // navigate(OVHAUTH + '/home');
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [setIsActive, resetIdleTimer]);

    // Mise en place du timer d'inactivité
    useEffect(() => {
        // Vérifiez que saverItems contient des éléments avant de démarrer le timer
            idleTimerRef.current = setTimeout(() => {
                setIsActive(false); // Démarre l'écran de veille seulement si saverItems n'est pas vide
            }, idleTime * 1000);
        return () => clearTimeout(idleTimerRef.current);
    }, [setIsActive]); // Ajoutez saverItems.length comme dépendance pour réinitialiser le timer si le nombre d'éléments change

    // Passage au prochain élément ou redémarrage de la vidéo
    const goToNextItem = useCallback(() => {
        setCurrentIndex(prevIndex => {
            if (saverItems.length === 1) {
                // Pour une seule vidéo, la redémarrer
                videoRef.current?.seekTo(0, 'seconds');
                return 0; // Reste sur le même index
            } else {
                // Passer au prochain élément ou revenir au premier
                return (prevIndex + 1) % saverItems.length;
            }
        });
    }, [saverItems.length]);

    // Gestion de l'affichage des images
    useEffect(() => {
        if (saverItems[currentIndex]?.file_type.startsWith("image/")) {
            clearTimeout(imageTimeoutRef.current);
            imageTimeoutRef.current = setTimeout(() => {
                goToNextItem();
            }, imageDisplayDuration);
        }

        return () => clearTimeout(imageTimeoutRef.current);
    }, [currentIndex, saverItems, goToNextItem]);


// Gestion de la fin d'une vidéo
    const onVideoEnded = useCallback(() => {
        if (saverItems.length > 1) {
            goToNextItem();
        }
        // Pas besoin de gérer le cas d'une seule vidéo ici,
        // car l'attribut 'loop' du ReactPlayer s'en chargera.
    }, [goToNextItem, saverItems.length]);

    useEffect(() => {
        const handleIframeInteraction = (event) => {
            if (event.data && event.data.type === 'interaction') {
                const { event: eventType, key } = event.data;
                if (eventType === 'click' || eventType === 'scroll' || (eventType === 'keydown' && key) || eventType === 'touchmove') {
                    resetIdleTimer();
                }
            }
        };

        window.addEventListener("click", resetIdleTimer);
        window.addEventListener("touchmove", resetIdleTimer);
        window.addEventListener("message", handleIframeInteraction);

        return () => {
            window.removeEventListener("click", resetIdleTimer);
            window.removeEventListener("touchmove", resetIdleTimer);
            window.removeEventListener("message", handleIframeInteraction);
        };
    }, [resetIdleTimer]);

    const handleClick = () => {
        console.log("Handle Click")
        // navigate(OVHAUTH + '/home');
    };

    // Used when data is being written to the device storage. Simulate tasks to keep the loadingScreen alive.
    const performJob = async (jobCount) => {
        while (jobCount > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };

    return (
        <>
            {jobCount > 0 ? (
                <LoadingScreen performJob={() => performJob(jobCount)} onFinishLoading={()=>{console.log("Medias stored to device storage.")}} loadingText={`INSTALLATION ET MISE EN VEILLE`}/>
            ) : (
                <>
                    {!isActive && isOnline && saverItems.length > 0 && (
                        <div className='myVideo block' onClick={handleClick} style={{top: 0, zIndex: 10000}}>
                            {saverItems.map((item, index) => (
                                <div key={index} style={{ display: currentIndex === index ? 'block' : 'none', height: "100%" }}>
                                    {item.file_type.startsWith("image/") ? (
                                        <img src={item.file} onLoad={goToNextItem} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                    ) : (
                                        <ReactPlayer
                                            width='100%'
                                            height='100%'
                                            url={item.file}
                                            loop={saverItems.length === 1}
                                            playing={index === currentIndex}
                                            muted
                                            ref={videoRef}
                                            onEnded={onVideoEnded}
                                        />
                                    )}
                                    <div className="screensaver-banner">
                                        <div className="screensaver-container">
                                            <img src="/img/touch.png" alt="" className="ss_img1"/>
                                            <img src="/img/text_screensaver.png" alt="" className="ss_img2"/>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ScreenSaver;
