import Widgets from "./Widget";
// import Loggout from "./Loggout";
import Position from "./Position";

const Header = () => {
  return (
    <div className="firstRow" style={{ willChange: 'transform' }}>
      <div className="col-sm-4">
        <Widgets />
      </div>
        <div className="col-sm-4 d-flex align-items-center justify-content-center">
            {/*<Loggout logo />*/}
        </div>
    <div className="col-sm-4">
        <Position />
    </div>
    </div>
  )
}
export default Header;