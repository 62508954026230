import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactPlayer from 'react-player';
import APIURL from "../api/dns";
import { fetchThumbnail, checkFileExists, writeToStorage, parseUrl, TV_localStoragePath, fetchVideos, getTizenVersion } from '../api/Tizen';
import LoadingScreen from "./LoadingScreen";

const Events = ({ isActive, writeJob = null, doneJob = null }) => {
    const [events, setEvents] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    const [videoEnded, setVideoEnded] = useState(false);
    const timerRef = useRef(null);
    const videoKeyRef = useRef(0);

    const IMAGE_TYPES = ["image/jpeg", "image/png"];
    const VIDEO_TYPE = "video/mp4";

    const isCurrentEventVideo = useCallback(() => events[currentIndex]?.file_type === VIDEO_TYPE, [events, currentIndex]);

    const fetchEvents = useCallback(async () => {
        try {
            const resp = await fetch(APIURL + "/api/display/banner/", {
                method: "GET",
                headers: {
                    "Device-UUID": localStorage.getItem('Device-UUID'),
                    "Device-Code": localStorage.getItem('Device-Code')
                }
            });
            let data = await resp.json();
            if (window.tizen) {
                for (let i = 0; i < data.length; i++) {
                    const urlData = parseUrl(data[i].fetchThumbnail ? data[i].fetchThumbnail : data[i].file, data[i].id, 'b');
                    let filePath = await checkFileExists(urlData.file);
                    if (!filePath) {
                        setJobCount(prevCount => prevCount + 1);
                        if (writeJob)
                            writeJob();
                        let eventData;
                        if (urlData.extension === "mp4") {
                            eventData = await fetchVideos(urlData.url);
                        } else {
                            eventData = await fetchThumbnail(urlData.url);
                        }
                        await writeToStorage(urlData.file, eventData, () => {
                            if (doneJob)
                                doneJob();
                            setJobCount(prevCount => prevCount - 1);
                        });
                    }
                    const newUrl = `${TV_localStoragePath}${urlData.file}`;
                    data[i].file = newUrl;
                    data[i].thumbnail = newUrl;
                }
            }
            setEvents(data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
            // Handle error or set an error state here
        }
    }, [writeJob, doneJob, events]);

    useEffect(() => {
        fetchEvents();
    }, []);

    const switchToNextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
    }, [events.length]);

    const restartTimer = useCallback(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(switchToNextSlide, 5000);
    }, [switchToNextSlide]);

    const restartVideo = useCallback(() => {
        if (!isCurrentEventVideo() && events.length > 0) {
            restartTimer();
        }
    }, [isCurrentEventVideo, events.length, restartTimer]);

    const onVideoEnd = useCallback(() => {
        setVideoEnded(true);
        videoKeyRef.current += 1;
        switchToNextSlide();
        restartVideo();
        setVideoEnded(false);
    }, [restartVideo, switchToNextSlide]);

    useEffect(() => {
        if (!isCurrentEventVideo() && events.length > 0) {
            timerRef.current = setTimeout(switchToNextSlide, 5000);
        }

        return () => clearTimeout(timerRef.current);
    }, [currentIndex, events, isCurrentEventVideo, switchToNextSlide]);

    useEffect(() => {
        restartVideo();
        return () => clearTimeout(timerRef.current);
    }, [currentIndex, events, restartVideo]);

    const renderEvent = useCallback((event, index) => {
        if (!event) {
            return null;
        }
        const isImage = IMAGE_TYPES.includes(event.file_type);
        const aspectRatio = isImage ? undefined : event.aspect_ratio;
        return (
            <div key={index} style={{ display: 'flex', position: 'relative', width: '100%', height: '100%', maxHeight: '500px', alignItems: 'center', justifyContent: 'center'}}>
                {isImage ? (
                    <img className="center-crop" src={event.file} alt={`Slide ${index}`} />
                ) : (
                    (events.length === 1 ? (
                        <ReactPlayer
                            key={`${event.file}${videoKeyRef.current}`}
                            url={event.file}
                            playing={index === currentIndex}
                            onEnded={onVideoEnd}
                            muted
                            loop
                            width="100%"
                            height="100%"
                            style={{
                                width: "100vw",
                                minHeight: "100%",
                                minWidth: "100%",
                                objectFit: "contain",
                                objectPosition: "center",
                                position: 'relative',
                            }}
                        />
                    ) : (
                        <ReactPlayer
                            key={`${event.file}${videoKeyRef.current}`}
                            url={event.file}
                            playing={index === currentIndex}
                            onEnded={onVideoEnd}
                            muted
                            width="100%"
                            height="100%"
                            style={{
                                width: "100vw",
                                minHeight: "100%",
                                minWidth: "100%",
                                objectFit: "contain",
                                objectPosition: "center",
                                position: 'relative',
                            }}
                        />
                    ))
                )}
            </div>
        );
    }, [currentIndex, onVideoEnd]);
    
    // Used when data is being written to the device storage. Simulate tasks to keep the loadingScreen alive.
    const performJob = async (jobCount) => {
        while (jobCount > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };

    return (
        <>
            {jobCount > 0 ? (
                <LoadingScreen performJob={() => performJob(jobCount)} onFinishLoading={()=>{}} loadingText={`INSTALLATION DE LA BANNIERE...`}/>
            ) : (
                <>
                    {isActive && events.length > 0 && (
                        <div className="carousel-container">
                            {renderEvent(events[currentIndex], currentIndex)}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Events;
