import { useEffect, useState } from "react";
import axios from 'axios';
import 'weather-icons/css/weather-icons.css';
import "bootstrap-icons/font/bootstrap-icons.css";

const Weather = ({ latitude, longitude, locationName }) => {
    const cardinalDirections = ["Nord", "Nord-Est", "Est", "Sud-Est", "Sud", "Sud-Ouest", "Ouest", "Nord-Ouest"];

    const weatherIconMap = {
        0: "wi wi-day-sunny",
        1: "wi wi-day-cloudy",
        2: "wi wi-cloudy",
        3: "wi wi-cloudy-gusts",
        45: "wi wi-fog",
        48: "wi wi-fog",
        51: "wi wi-showers",
        53: "wi wi-showers",
        55: "wi wi-showers",
        56: "wi wi-rain",
        57: "wi wi-rain-mix",
        61: "wi wi-snow",
        63: "wi wi-snow",
        65: "wi wi-sleet",
        66: "wi wi-snow-thunderstorm",
        67: "wi wi-snow",
        71: "wi wi-snow",
        73: "wi wi-snow-wind",
        75: "wi wi-hail",
        77: "wi wi-thunderstorm",
        80: "wi wi-storm-showers",
        81: "wi wi-thunderstorm",
        82: "wi wi-thunderstorm",
        85: "wi wi-snow-thunderstorm",
        86: "wi wi-snow",
        95: "wi wi-thunderstorm",
        96: "wi wi-thunderstorm",
        99: "wi wi-thunderstorm"
    };

    const windDirectionMap = {
        "Nord": "bi bi-arrow-up-circle",
        "Nord-Est": "bi bi-arrow-up-right-circle",
        "Est": "bi bi-arrow-right-circle",
        "Sud-Est": "bi bi-arrow-down-right-circle",
        "Sud": "bi bi-arrow-down-circle",
        "Sud-Ouest": "bi bi-arrow-down-left-circle",
        "Ouest": "bi bi-arrow-left-circle",
        "Nord-Ouest": "bi bi-arrow-up-left-circle"
    };

    const [weatherData, setWeatherData] = useState({ weather: {}, iconWeather: null, iconWind: null });

    const apiURL = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true&timezone=auto`;

    const fetchWeather = async () => {
        if (!latitude || !longitude) return;

        try {
            const response = await axios.get(apiURL);
            const currentWeather = response.data.current_weather;
            setWeatherData({
                weather: currentWeather,
                iconWeather: getWeatherIcon(currentWeather.weathercode),
                iconWind: getWindIcon(currentWeather.winddirection)
            });
        } catch (error) {
            console.error("Error fetching weather data:", error);
        }
    };

    const getWeatherIcon = weatherCode => {
        return weatherIconMap[weatherCode] || "wi wi-na";
    };

    const getWindIcon = windDirection => {
        const index = Math.round(((windDirection %= 360) < 0 ? windDirection + 360 : windDirection) / 45) % 8;
        return windDirectionMap[cardinalDirections[index]] || "bi bi-question-circle";
    };

    useEffect(() => {
        fetchWeather();
    }, [latitude, longitude]);

    const { weather, iconWeather, iconWind } = weatherData;

    return (
        <>
            {latitude !== null && longitude !== null && (
                <div className="center-weather">
                    <div className={weather.temperature ? `weather-icon ${iconWeather}` : `weather-icon-loading`} style={{ color: "black", display: "flex", marginTop: "10px" }}>
                        <span style={{ fontFamily: 'Helvetica', marginLeft: "10px" }}> {weather.temperature ? Math.round(weather.temperature) : "---"}°C, {weather.windspeed ? weather.windspeed : "---"} km/h</span>
                    </div>
                    <div style={{ color: "black", display: "flex", wordBreak: "break-all" }}> {locationName}</div>
                </div>
            )}
        </>
    );
};

export default Weather;
