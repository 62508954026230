import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const LoadingScreen = ({ performJob, onFinishLoading, loadingText }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [finish, setFinish] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (performJob)
                    await performJob(); // Wait for the job function to finish if provided
                // setIsLoading(false);
                setFinish(true);
                // onFinishLoading();
            } catch (error) {
                console.error('Error while performing job:', error);
                // Handle error if needed
                setIsLoading(false);
                onFinishLoading(); // Finish loading even in case of error
            }
        };

        fetchData();

        // Clean up function
        return () => {
            // Clear any ongoing operations if needed
        };
    }, [performJob, onFinishLoading]);

    // This useEffect waits for 2s before stopping the loading screen to avoid screen flickering.
    useEffect(() => {
        let timeout;
        if (finish) {
            timeout = setTimeout(() => {
                setIsLoading(false);
                onFinishLoading();
            }, 2000); // Set isLoading to false after 2 seconds
        }

        return () => clearTimeout(timeout);
    }, [isLoading, finish]);

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red; // Change the color of the spinner
    `;

    const loadingScreenStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        zIndex: 1000,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        top: 0,
        overflow: 'hidden'
    };

    return (
        <div style={loadingScreenStyles} className="loading-screen">
            {isLoading ? (
                <>
                    <div className="spinner">
                        <RingLoader
                            css={override}
                            size={150} // Adjust the size of the spinner as needed
                            color={'#000000'} // Change the color of the spinner
                            loading={isLoading}
                            />
                    </div>
                    <p style={{ marginTop: '20px', textAlign: 'center' }}>{loadingText}</p>
                </>
            ) : null}
        </div>
    );
};

export default LoadingScreen;
