import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import QRCode from 'react-qr-code';

const VideoReader = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const qrBtnStyle = {
        zIndex: 4,
        right: 0,
        bottom: 0,
        position: "absolute",
        margin: 5
    }

    return (
        <>
            {props.downloadable &&
                // <Button variant="dark" onClick={handleShow} style={qrBtnStyle}>
                //     <FontAwesomeIcon icon={faQrcode} fontSize='36px' color="white"></FontAwesomeIcon>
                // </Button>}
            <div className="mybutton-QR btnPMR" style={{zIndex: 4}}>
                <a className="feedback" style={{
                    backgroundColor: "#343434",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }} onClick={handleShow}>
                    <QRCode value={props.url} size={50}></QRCode>
                </a>
            </div>
            }

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="centerModalTitle">Scannez pour télécharger</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="qr-container">
                        <QRCode value={props.url}></QRCode>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default VideoReader;