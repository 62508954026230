import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Block from "../components/Block";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faHouse } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";
import Events from "../components/Events";
import APIURL from "../api/dns";
import OVHAUTH from "../token-ovh/ovh-auth";
import { fetchThumbnail, writeToStorage, parseUrl, TV_localStoragePath } from '../api/Tizen';
import LoadingScreen from '../components/LoadingScreen';

const Page = (props) => {
    // const [productList, setProductList] = useState([]);
    // const [categoryList, setCategoryList] = useState([]);
    const [indexedCatalog, setIndexedCatalog] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [filtreColor, setFiltreColor] = useState(""); // State for storing filter color
    // const [productsBckgPaths, setProductsBckgPaths] = useState([]);
    // const [categoriesBckgPaths, setCategoriesBckgPaths] = useState([]);
    const [blockBackgroundPath, setBlockBackgroundPath] = useState([]);
    const [jobCount, setJobCount] = useState(0);
    const URL_TOKEN = localStorage.getItem('Url-Token');
    const isTokenAuthenticated = URL_TOKEN;

    const param = useParams();

    useEffect(() => {
        // Fetch settings function
        const fetchSettings = async () => {
            const CODE = localStorage.getItem('Device-Code');
            const apiUrl = `${APIURL}/api/auth/organization/settings`;
    
            try {
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Device-Code": CODE,
                        "Device-UUID": localStorage.getItem('Device-UUID')
                    }
                });
                const data = await response.json();
                if (response.ok && data[0]?.color) {
                    setFiltreColor(data[0].color);
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        // Fetch page data
        const fetchPage = async () => {
            const apiUrl = `${APIURL}/api/catalog/sorting/${param.categoryId}`;
            try {
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Device-Code": localStorage.getItem('Device-Code'),
                        "Device-UUID": localStorage.getItem('Device-UUID')
                    }
                });
                const data = await response.json();
                const sortedCatalog = [...data.catalog];
                sortedCatalog.sort((a, b) => a.position - b.position);
                if (response.ok) {
                    const blockBckgPaths = [];
                    sortedCatalog.forEach(block => {
                        if (block.category) {
                            let urlData = parseUrl(block.category.thumbnail ? block.category.thumbnail : block.category.file, block.category.id, 'c');
                            if (window.tizen) {
                                urlData.url = `${TV_localStoragePath}${urlData.file}`;
                            }
                            blockBckgPaths.push(urlData.url);
                        } else {
                            let urlData = parseUrl(block.product.thumbnail ? block.product.thumbnail : block.product.file, block.product.id, 'p');
                            if (window.tizen) {
                                urlData.url = `${TV_localStoragePath}${urlData.file}`;
                            }
                            blockBckgPaths.push(urlData.url);
                        }
                    });
                    setBlockBackgroundPath(blockBckgPaths);
                    setIndexedCatalog(sortedCatalog);
                    setParentId(data.parent);
                }
            } catch (error) {
                console.error('Error fetching page data:', error);
            }
        };
        fetchSettings();
        fetchPage();
    }, [param]);

    const imageLoadSuccess = (event, data) => {
        // In case the image loaded successfully, do something?
    }

    const imageLoadFailed = async (event, index, data, isCategory, identifier, type) => {
        const url = data.thumbnail ? data.thumbnail : data.file
        if (window.tizen ) {
            setJobCount(prevCount => prevCount + 1);
            const urlData = parseUrl(url, identifier, type);
            let data = await fetchThumbnail(url);
            try {
                await writeToStorage(urlData.file, data, () => {
                    setJobCount(prevCount => prevCount - 1); // Decrement jobCount once storage write is completed to disable loading screen
                });
            } catch (e) {
                console.error("Failed to write to device storage.");
                setJobCount(prevCount => prevCount - 1);
            }
        }
        setBlockBackgroundPath(prevPaths => {
            const updatedPaths = [...prevPaths];
            updatedPaths[index] = url; // Image path is always under the Images folder;
            return updatedPaths;
        })
        // if (isCategory) {
        //     setCategoriesBckgPaths(prevPaths => {
        //         const updatedPaths = [...prevPaths];
        //         updatedPaths[index] = url; // Image path is always under the Images folder;
        //         return updatedPaths;
        //     });
        // } else {
        //     setProductsBckgPaths(prevPaths => {
        //         const updatedPaths = [...prevPaths];
        //         updatedPaths[index] = url; // Image path is always under the Images folder;
        //         return updatedPaths;
        //     });
        // }
    }

    // Used when data is being written to the device storage. Simulate tasks to keep the loadingScreen alive.
    const performJob = async (jobCount) => {
        while (jobCount > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };

    return (
        <>
            {jobCount > 0 ? (
                <LoadingScreen performJob={() => performJob(jobCount)} onFinishLoading={()=>{console.log("Medias stored to device storage.")}} loadingText={`INSTALLATION DES MEDIAS DE LA PAGE...`}/>
            ) : (
                <>
                    <Link to={!parentId || parentId.parent === null ? `${OVHAUTH}/home` : `${OVHAUTH}/category/${parentId.parent}?stats=ignore`} className='goBack'>
                        <img src="/img/back.png" alt="Go Back" style={{ width: '100%', height: '100%' }} />
                    </Link>
                    <Link to={`${OVHAUTH}/home`} className='goHome'>
                        <img src="/img/home.png" alt="Home" style={{ width: '100%', height: '100%' }} />
                    </Link>

    
                            <Header />
                            <div className="events">
                                <Events setIsActive={props.setIsActive} isActive={props.isActive}/>
                            </div>
            
                    <div className="row">
                        {indexedCatalog.map((block, index) => {
                            if (block.category) {
                                return (
                                    <Block key={index.toString()} index={index} showName={block.category.show_name} title={block.category.name} path={OVHAUTH + "/category/" + block.category.id} disableFilter={true} filtreColor={filtreColor} onLoad={(event) => imageLoadSuccess(event)} onError={(event) => imageLoadFailed(event, index, block.category, true, block.category.id, 'c')} imgPath={blockBackgroundPath[index]} identifier={block.category.id} type={'c'}/>
                                );
                            } else {
                                return (
                                    <Block key={index.toString()} index={index + indexedCatalog.length} showName={block.product.show_name} downloadable={block.product.downloadable} title={block.product.name} path={OVHAUTH + "/product/" + block.product.id} filtreColor={filtreColor} onLoad={(event) => imageLoadSuccess(event)} onError={(event) => imageLoadFailed(event, index, block.product, false, block.product.id, 'p')} imgPath={blockBackgroundPath[index]} identifier={block.product.id} type={'p'}/>
                                );
                            }
                        })}
                    </div>
                </>
            )}
        </>
    );
}
export default Page;